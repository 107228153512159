<template>

  <div>
    <Header />
  
    <section class="ftco-about img alturaimob" style="padding-top: 20px;padding-bottom: 0px;">
      <div class="container">
        <div class="col-md-12 heading-section text-center ">
          <h3 class="mb-4 alturaimgcel" style="color: #EB174E;font-weight: 800;">{{ TituloTexto }}</h3>
        </div>
        <div class="col-md-12 heading-section text-center ">
           <span v-html="ResumoTexto1"></span>
        </div>
      </div>
    </section>

    <section class="ftco-project" id="projects-section" style="padding-top: 20px;padding-bottom: 0px;">
      <div class="container">
        <div class="row">
            <div class="col-md-4" v-for="itens in itens1residencial" :key="itens.institucionalId" style="text-align: center;">
              <div>
                <img :src="$apiUrlIndex + itens.institucionalImg[0].urlImagem" style="width: 40px;height: auto;"/><br>
                <h3 style="color: #03028C;font-weight: 800;font-size: 20px;padding-top: 18px;padding-left: 5px;">{{ itens.nome }}</h3>
              </div>
            </div>
        </div>
      </div>
    </section>

    <section class="ftco-about img alturaimob" style="padding-top: 60px;padding-bottom: 0px;">
      <div class="container">
        <div class="col-md-12 heading-section text-center ">
           <span v-html="ResumoTexto2"></span>
        </div>
      </div>
    </section>

    <!--section  style="padding-top: 30px;padding-bottom: 0px;">
      <div class="container">
        <div class="row">
            <div class="col-md-4" v-for="itens in itens2residencial" :key="itens.institucionalId" style="display: inline-flex;">
              <div>
                <img :src="$apiUrlIndex + itens.institucionalImg[0].urlImagem" style="width: 40px;height: auto;" />
              </div>
              <div>
                <h3 style="color: #03028C;font-weight: 800;font-size: 20px;padding-top: 18px;padding-left: 5px;">{{ itens.nome }}</h3>
              </div>
            </div>
        </div>
      </div>
    </!--section-->

    <section class="ftco-project" id="projects-section" style="padding-top: 60px;padding-bottom: 0px;">
      <div class="container">
        <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
              <table>
                  <thead>
                    <tr>
                      <th style="text-align: left;">Coberturas</th>
                      <th style="text-align: right;">Plano 30</th>
                      <th style="text-align: right;">Plano 50</th>
                      <th style="text-align: right;">Plano 100</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="itemb in tabela1residencial" :key="itemb.institucionalId">
                      <td style="text-align: left; color:#03028C;" :class="{ 'blue-row': itemb.destaque }">{{ itemb.nome }}</td>
                      <td style="text-align: right; color:#EB174E;" :class="{ 'blue-row': itemb.destaque }">{{ itemb.referencia }}</td>
                      <td style="text-align: right; color:#EB174E;" :class="{ 'blue-row': itemb.destaque }">{{ itemb.campoExtra1 }}</td>
                      <td style="text-align: right; color:#EB174E;" :class="{ 'blue-row': itemb.destaque }">{{ itemb.campoExtra2 }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12">
              <span style="color:#EB174E;font-size: 18px;font-weight: 800;padding-top: 10px;"> {{ RodapeTab1 }}</span>
            </div>
        </div>
      </div>
    </section>

    <section class="ftco-project" id="projects-section" style="padding-top: 60px;padding-bottom: 60px;">
      <div class="container">
        <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
              <table>
                  <thead>
                    <tr>
                      <th style="text-align: center;">Coberturas</th>
                      <th style="text-align: center;">Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="itemb in tabela2residencial" :key="itemb.institucionalId">
                      <td style="text-align: center; color:#03028C;" :class="{ 'blue-row': itemb.destaque }">{{ itemb.nome }}</td>
                      <td style="text-align: center; color:#EB174E;" :class="{ 'blue-row': itemb.destaque }">{{ itemb.referencia }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12">
              <span style="color:#EB174E;font-size: 18px;font-weight: 800;padding-top: 10px;"> {{ RodapeTab2 }}</span>
            </div>
        </div>
      </div>
    </section>

    <section class="ftco-about img alturaimob" style="padding: 60px;text-align: center;background-color: #d6cdcd;">
      <div class="container" >
        <div class="row">
          <div class="col-md-12">
            <h2 style="padding-bottom: 30px;">{{TituloChamada}}</h2>
            <p><a :href="LinkChamada" target="_blank" class="btn btn-primary py-3 px-4" style="border-radius:25px;">{{TextoBtnChamada}}</a></p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import Header from '../shared/Header.vue'

export default {
  components: {
    Header,
  },
  data() {
    return {
      TextoResidencial:[],
      TituloTexto:'',
      ResumoTexto1:'',
      ResumoTexto2:'',
      RodapeTab1:'',
      RodapeTab2:'',
      itens1residencial:[],
      itens2residencial:[],
      tabela1residencial:[],
      tabela2residencial:[],
      chamadaresidencial:[],
      TituloChamada:'',
      TextoBtnChamada:'',
      LinkChamada:'',
    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/textovida")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.TextoResidencial = retornoAPI
      if (this.TextoResidencial!=''){
          this.TituloTexto = this.TextoResidencial[0].nome
          this.ResumoTexto1 = this.TextoResidencial[0].resumo1
          this.ResumoTexto2 = this.TextoResidencial[0].resumo2
          this.RodapeTab1 = this.TextoResidencial[0].campoExtra1
          this.RodapeTab2 = this.TextoResidencial[0].campoExtra2
      }
    });

    this.$http
        .get(this.$apiUrl + "/institucional/telas/itensvida")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.itens1residencial = retornoAPI.filter((x) => x.visivel).sort(function (a, b) {
            if (a.ordem > b.ordem) { return 1; }       
            if (a.ordem < b.ordem) { return -1; }        
            return 0;
          })

        });

        /*this.$http
        .get(this.$apiUrl + "/institucional/telas/itens2residencial")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.itens2residencial = retornoAPI.filter((x) => x.visivel).sort(function (a, b) {
            if (a.ordem > b.ordem) { return 1; }       
            if (a.ordem < b.ordem) { return -1; }        
            return 0;
          })

        });*/

        this.$http
        .get(this.$apiUrl + "/institucional/telas/tabela1vida")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.tabela1residencial = retornoAPI.filter((x) => x.visivel).sort(function (a, b) {
            if (a.ordem > b.ordem) { return 1; }       
            if (a.ordem < b.ordem) { return -1; }        
            return 0;
          })

        });

        this.$http
        .get(this.$apiUrl + "/institucional/telas/tabela2vida")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.tabela2residencial = retornoAPI.filter((x) => x.visivel).sort(function (a, b) {
            if (a.ordem > b.ordem) { return 1; }       
            if (a.ordem < b.ordem) { return -1; }        
            return 0;
          })

        });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/chamadavida")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.chamadaresidencial = retornoAPI
      if (this.chamadaresidencial!=''){
          this.TituloChamada = this.chamadaresidencial[0].nome
          this.TextoBtnChamada = this.chamadaresidencial[0].textoBtn1
          this.LinkChamada = this.chamadaresidencial[0].linkBtn2
      }
    });
       

  },
  methods: {
  }
  
}

</script>

<style scoped>
@media (max-width:768px) {
   .alturaimob {
      padding-top: 60px !important;
  }

}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #03028C !important;
  padding: 8px;
}

th {
  background-color: #03028C;
  color:#ffffff;
}
.blue-row {
  background-color:#03028C;
  color: #ffffff !important; /* Define a cor do texto para branco para melhor legibilidade */
}

</style>