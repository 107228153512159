<template>
  <div id="app">

    <div v-html="codigo_SEO"></div>

   <!--   <Header />  -->

    <transition name="fadeIn">
      <router-view></router-view>
    </transition>

    <Footer />
    <div v-if="LinkWhats!=''" class="whatsapp-fixo">
      <a :href="LinkWhats[0].referencia" target="_blank">
          <img src="images/atendimentowats.png"  alt="Fale Conosco pelo WhatsApp" />
      </a>
     </div>
  </div>
</template>

<script>

 // import Header from './components/site/shared/Header.vue'
  import Footer from './components/site/shared/Footer.vue'

  export default {
    name: 'App',
    components: {
      // Header,
      Footer
    },
    data() {
      return {

        seobody: [],
        codigo_SEO: '',
        seoHead:[],
        codigoHead: '',
        seoAnalitics:[],
        codigoAnalitics: '',
        metaHome: [],
        codigoHome: '',
        LinkWhats:[],
        
      }
    },
    created() {
      
      this.$http
      .get(this.$apiUrl + "/seobody/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seobody = retornoAPI
        if (this.seobody!= ''){
           this.codigo_SEO = this.seobody[0].codigo
        }
      });


      this.$http
      .get(this.$apiUrl + "/seohead/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seoHead = retornoAPI;
        if (this.seoHead!='' && this.seoHead!= null){
             this.codigoHead = this.seoHead[0].codigo;
             if (this.codigoHead!= null && this.codigoHead!=''){
               document.head.innerHTML += this.codigoHead.trim('"');
             }
        }
        
      });


     this.$http
      .get(this.$apiUrl + "/seoanalitycs/")
      .then((res) => res.json())
      .then((idi) => {
        this.seoAnalitics = idi;
        if (this.seoAnalitics!='' && this.seoAnalitics != null){
            this.codigoAnalitics = this.seoAnalitics[0].codigo;
             if (this.codigoAnalitics!= null && this.codigoAnalitics!=''){
               document.head.innerHTML += this.codigoAnalitics.trim('"');
             }
        }
        
      });


      this.$http
      .get(this.$apiUrl + "/Seometahome/")
      .then((res) => res.json())
      .then((idi) => {
        this.metaHome = idi;
        if (this.metaHome!='' && this.metaHome != null){
          this.codigoHome = this.metaHome[0].codigo;
          if (this.codigoHome!= null && this.codigoHome!=''){
            document.head.innerHTML += this.codigoHome.trim('"');
          }
        }
      });

       this.$http
      .get(this.$apiUrl + "/institucional/telas/linkwhatsapp")
      .then((res) => res.json())
      .then((idi) => {
        this.LinkWhats = idi;
      });

    },
     methods: {
  }
}
</script>

<style>

  @import '/css/animate.css';
  @import '/css/owl.carousel.min.css';
  @import '/css/owl.theme.default.min.css';
  @import '/css/magnific-popup.css';
  @import '/css/flaticon.css';
  @import '/css/style.css';


  /* ---------  */
  .ql-align-justify {
  text-align: justify !important;
  }
  .ql-align-right{
  text-align: right !important;
  }

  .ql-align-center{
  text-align: center !important;
  }


  /*---------- Animations */
  .fadeIn-enter {
    opacity: 0;
  }
  .fadeIn-enter-active {
    transition: opacity .8s;
  }
  .fadeIn-enter-to {
  opacity: 1;
  }

@media (max-width:768px) {
    .hidden-xs {
        display: none !important;
    }

}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important;
    }

  }


@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important;
    }

    .alturatopo {
      margin-top: -70px;
}

}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important;
    }

   .hero-wrap {
    width: 100%;
    height: 100%;
    z-index: 0;
    padding-top: 0px !important;
}

.alturatopo {
      margin-top: -60px;
}

}

@media (max-width:767px) {
  .alturaimgcel {
    padding-top: 170px;
}

}

@media (min-width:768px) and (max-width:991px) {
  .alturaimgcel {
    padding-top: 120px;
}

}
  /* WHATSAPP FIXO */
  .whatsapp-fixo {
      position: fixed;
      bottom: 6px;
      right: 55px;
      z-index: 999;
  }

  .ql-size-large {
    font-size: 1.5em;
}

p{
  margin-bottom: 0rem !important;
  font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
}

</style>
