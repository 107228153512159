<template>
  
  <section  v-if="DepoimentosFundo!='' && DepoimentosFundo!=null"
   class="ftco-section testimony-section img img-bg border"
    :style="{ backgroundImage: 'url(' + $apiUrlIndex + DepoimentosFundo[0].institucionalImg[0].urlImagem + ')' }"
  >
  <div class="overlay"></div>
    <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-md-12 text-center heading-section heading-section-white ">
            <span class="subheading">{{ DepoimentosFundo[0].nome}}</span>
            <h2 class="mb-3">{{ DepoimentosFundo[0].campoExtra1}}</h2>
          </div>
        </div>
      <div class="row">
        <div class="col">
          <div>
            <carousel
              id="carousel-1"
                      autoplay
                      :autoplayTimeout=4000
                       autoplayHoverPause
                      :paginationEnabled="false"
                      :paginationSize=0
                      :loop="true"
              :perPageCustom="[[340, 1],[768, 1], [1024,3], [1600,3]]"
              v-if="CadDepoimentos!='' && CadDepoimentos!=null"
            >

              <!-- 1 -->
              <slide 
                v-for="depoimentos in CadDepoimentos" :key="depoimentos.institucionalId"
                img-blank img-alt="Blank image" style="padding-right: 10px !important;"
              >
              <div class="row">
                 <div class="col-md-12">
                <div class="carousel-testimony item testimony-wrap py-4">
                  <div class="icon d-flex align-items-center justify-content-center"><span class="fa fa-quote-left"></span>
                  </div>
                  <div class="text">
                    <p class="mb-4" v-html="depoimentos.resumo1"></p>
                    <div class="d-flex align-items-center">
                      <div v-if="depoimentos.institucionalImg!=''" class="user-img" :style="{ backgroundImage: 'url(' + $apiUrlIndex + depoimentos.institucionalImg[0].urlImagem + ')' }"></div>
                      <div class="pl-3">
                        <p class="name">{{ depoimentos.nome }}</p>
                        <span class="position">{{ depoimentos.campoExtra1 }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>
              </slide>

            </carousel>

          </div>
        </div>
      </div>
    </div>
  </section>

</template>


<script>
 import { Carousel, Slide } from 'vue-carousel';
export default {
  components: {
            Carousel,
            Slide,
        },
  data() {
    return {
      DepoimentosFundo:[],
      CadDepoimentos:[],
    }
  },
  created() {

     this.$http
    .get(this.$apiUrl + "/institucional/telas/DepoimentosFundo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.DepoimentosFundo = retornoAPI
    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/CadDepoimentos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.CadDepoimentos = retornoAPI.filter((x) => x.visivel)

      this.CadDepoimentos.sort(function() {
          return .5 - Math.random();
        });
    });

  }
}
</script>

<style>
.VueCarousel-wrapper {
    width: 100%;
    position: relative;
    overflow: visible !important;
}
</style>