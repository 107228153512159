<template>

  <div>
    <Header />

    <section class="hero-wrap js-fullheight alturaimg alturatopo">
      <div class="container-fluid px-0">
        <div class="row d-md-flex no-gutters slider-text align-items-center justify-content-end">
          <img class="one-third align-content-center order-md-last img-fluid alturaimgcel hidden-xs hidden-sm" :src="imgImob" alt="">
          <div class="one-forth d-flex align-items-center ">
            <div class="text mt-5" style="padding-top: 30px;">
              <span class="subheading">{{ tituloPeqImob }}</span>
              <h1>{{ tituloGrImob }}</h1>
              <p v-html="textoImob"></p>
              <!--p><a :href="linkImob" class="btn btn-primary py-3 px-4" style="border-radius:25px;">{{ txtBtnImob }}</a>
              </p-->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-about img alturaimob" style="padding: 60px;text-align: center;background-color: #d6cdcd;">
      <div class="container" >
        <div class="row">
          <div class="col-md-12">
            <h2 style="padding-bottom: 30px;">Através deste painel inclua e administre as solicitação de crédito.</h2>
            <p><a v-if="loginLink != '' && loginLink != null" :href="loginLink[0].campoExtra2" target="_blank" class="btn btn-primary py-3 px-4" style="border-radius:25px;">Plataforma de Garantia Locatícia</a></p>
          </div>
        </div>
      </div>
    </section>

    
    <section class="ftco-about img alturaimob" id="about-section" style="padding-top: 30px;padding-bottom: 0px;">
      <div class="container">
        <div class="col-md-12 heading-section text-center ">
          <h2 v-if="chamada!= ''" class="mb-4 alturaimgcel" v-html="chamada[0].descricao1"></h2>
        </div>
      </div>
    </section>

    <section class="ftco-project" id="projects-section">
      <div class="container">
        <div class="row">
          <div v-for="itens in imobiliarias" :key="itens.institucionalId" class="col-md-3">
            <div class="project img  d-flex justify-content-center align-items-end"
              :style="{ backgroundImage: 'url(' + $apiUrlIndex + itens.institucionalImg[0].urlImagem + ')', height: '200px', borderRadius: '15px' }">
              <div class="overlay"></div>
              <div class="text p-4">
                <h3 style="color: #ffffff;font-weight: 800;">{{ itens.nome }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-section ftco-no-pb ftco-no-pt" id="chapter-section">
      <div class="container">
        <div class="row justify-content-center py-5 mt-5">
          <div class="col-md-12 heading-section text-center ">
            <h2 class="mb-4">{{ tituloLucro }}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 mb-4">
            <nav id="navi">
              <ul>
                <li v-for="itemtit in ListaDetalhes" :key="itemtit.institucionalId">
                <a :href="'#' + itemtit.institucionalId">{{ itemtit.nome }}</a></li>
              </ul>
            </nav>
          </div>
          <div class="col-md-9">
            <div v-for="itemtitdet in ListaDetalhes" :key="itemtitdet.institucionalId" :id="itemtitdet.institucionalId"
              class="page bg-light one">
              <h2 class="heading">{{ itemtitdet.nome }}</h2>
              <p v-html="itemtitdet.descricao1"></p>
            </div>
          </div>
        </div>
      </div>
    </section>


    <Depoimentos />
    <section v-if="ListaFaq!=''" class="ftco-section ftco-no-pb ftco-no-pt" id="chapter-section">
      <div class="container">
        <div class="row justify-content-center py-5 mt-5">
          <div class="col-md-12 heading-section text-center ">
            <h2 class="mb-4">Dúvidas, consulte nossa FAQ</h2>
          </div>
        </div>
        <div class="row justify-content-center">
            <vsa-list>
              <vsa-item v-for="faq in ListaFaq" :key="faq.institucionalId">
                <vsa-heading>
                  {{ faq.nome }}
                </vsa-heading>
                <vsa-content>
                  <p v-html="faq.resumo1"></p>
                </vsa-content>
              </vsa-item>
            </vsa-list>
        </div>
      </div>
    </section>

    <section v-if="parceiroslogos!='' && parceiroslogos!=null" class="ftco-section ftco-partner">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg col-6" v-for="parceiros in parceiroslogos" :key="parceiros.institucionalId">
            <a :href="parceiros.linkBtn1" class="partner d-flex justify-content-center"><img
                :src="$apiUrlIndex + parceiros.institucionalImg[0].urlImagem" class="img-fluid"
                :alt="parceiros.nome"></a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from '../shared/Header.vue'
import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    Header,
    Depoimentos,
  },
  data() {
    return {
      parceiroslogos:[],
      Topo:[],
      imgImob:'',
      tituloPeqImob:'',
      tituloGrImob:'',
      textoImob:'',
      linkImob:'',
      txtBtnImob:'',
      contatoEmail:'',
      chamada:[],
      imobiliarias:[],
      títulolucros:[],
      tituloLucro:'',
      tituloslucros:[],
      ListaDetalhes:[],
      ListaFaq:[],
      loginLink:[],
    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/bannertopoimob.")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.Topo = retornoAPI
      if (this.Topo!=''){
          this.tituloPeqImob = this.Topo[0].nome
          this.tituloGrImob = this.Topo[0].campoExtra1
          this.contatoEmail = this.Topo[0].referencia
          this.textoImob = this.Topo[0].resumo2
          this.txtBtnImob = this.Topo[0].textoBtn1
          this.linkImob = this.Topo[0].linkBtn1
          this.imgImob = this.$apiUrlIndex + this.Topo[0].institucionalImg[0].urlImagem  
      }
    });

     this.$http
        .get(this.$apiUrl + "/institucional/telas/parceiroslogos")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.parceiroslogos = retornoAPI.filter((x) => x.visivel)

          this.parceiroslogos.sort(function() {
          return .5 - Math.random();
        });
        });

     this.$http
        .get(this.$apiUrl + "/institucional/telas/chamadaimob")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.chamada = retornoAPI
        });

      this.$http
        .get(this.$apiUrl + "/institucional/telas/itensimob")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.imobiliarias = retornoAPI.filter((x) => x.visivel)

          this.imobiliarias.sort(function (a, b) {
            if (a.ordem > b.ordem) { return 1; }       
            if (a.ordem < b.ordem) { return -1; }        
            return 0;
          })
       });

      this.$http
        .get(this.$apiUrl + "/institucional/telas/títulolucros")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.tituloslucros = retornoAPI
          if (this.tituloslucros!=''){
            this.tituloLucro = this.tituloslucros[0].nome
          }
      });

      this.$http
        .get(this.$apiUrl + "/institucional/telas/itenslucro")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.ListaDetalhes = retornoAPI.filter((x) => x.visivel)

          this.ListaDetalhes.sort(function (a, b) {
            if (a.ordem > b.ordem) { return 1; }       
            if (a.ordem < b.ordem) { return -1; }        
            return 0;
          })
       });

         this.$http
        .get(this.$apiUrl + "/institucional/telas/faqimob")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.ListaFaq = retornoAPI.filter((x) => x.visivel)

          this.ListaFaq.sort(function (a, b) {
            if (a.ordem > b.ordem) { return 1; }       
            if (a.ordem < b.ordem) { return -1; }        
            return 0;
          })
       });

       this.$http
      .get(this.$apiUrl + "/institucional/telas/loginlink")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.loginLink = retornoAPI

      });
       

  },
  methods: {
  }
  
}

</script>

<style>
@media (max-width:768px) {
   .alturaimob {
      padding-top: 60px !important;
  }

}

</style>