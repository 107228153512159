<template>

  <div>
    <Header />

    <section class="hero-wrap js-fullheight alturaimg alturatopo">
      <div class="overlay"></div>
      <div class="container-fluid px-0">
        <div class="row d-md-flex no-gutters slider-text align-items-center js-fullheight justify-content-end">
          <img class="one-third align-content-center order-md-last img-fluid alturaimgcel" :src="imgImob" alt="">
          <div class="one-forth d-flex align-items-center ">
            <div class="text mt-5" style="padding-top: 30px;">
              <span class="subheading">{{ tituloPeqImob }}</span>
              <h1>{{ tituloGrImob }}</h1>
              <p v-html="textoImob"></p>
              <p><a :href="linkImob" class="btn btn-primary py-3 px-4">{{ txtBtnImob }}</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-about img alturaimob" id="about-section">
      <div class="container alturaimgcel">
        <div class="row d-flex no-gutters">
          <div class="col-md-6 col-lg-6 d-flex">
            <div class="img-about img d-flex align-items-stretch">
              <div class="overlay"></div>
              <div class="img d-flex align-self-stretch align-items-center"
                :style="{ backgroundImage: 'url(' + imgConheca + ')', minHeight: '560px' }">
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 pl-md-5">
            <div class="row justify-content-start pb-3 px-4">
              <div class="col-md-12 heading-section ">
                <h2 class="mb-4">{{ tituloConheca }}</h2>
                <p v-html="textoConheca"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-about img" id="about-section" style="padding-top: 40px;padding-bottom: 20px;">
      <div class="container">
        <div class="col-md-12 heading-section text-center ">
          <h2 class="mb-4">{{ tituloDepende }}</h2>
        </div>
      </div>
    </section>

    <section class="ftco-no-pt">
      <div class="container">
        <div class="row">
          <div v-for="funciona in ComoFunciona" :key="funciona" class="col-md-4 text-center d-flex ">
            <div class="services-1 bg-light">
              <span class="icon">
                <!--i class="flaticon-user-experience"></i-->
                <img :src="$apiUrlIndex + funciona.institucionalImg[0].urlImagem" style="width: 50px;" />
              </span>
              <div class="desc">
                <h3 class="mb-5" v-html="funciona.resumo1"></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-section contact-section ftco-no-pb" id="contact-section" style="margin-bottom: 60px;">
      <div class="container">
        <div class="row justify-content-center mb-2">
          <div class="col-md-10 heading-section text-center ">
            <h2 class="mb-4">{{ tituloEncontre }}</h2>
            <p v-html="textoEncontre"></p>
          </div>
        </div>

        <div class="row no-gutters block-9">
          <div class="col-md-3"></div>
          <div class="col-md-6 order-md-last d-flex">
            <form action="#" class="bg-light p-4 p-md-5 contact-form">
              <div class="form-group">
                <input type="text" v-model="nome" class="form-control" placeholder="Nome">
              </div>
              <div class="form-group">
                <input type="text" v-model="email" class="form-control" placeholder="E-mail">
              </div>
              <div class="form-group">
                <input type="text" v-model="telefone" class="form-control" placeholder="Whatsapp">
              </div>
              <div class="form-group">
                <input type="text" v-model="estado" class="form-control" placeholder="Estado">
              </div>
              <div class="form-group">
                <input type="text" v-model="cidade" class="form-control" placeholder="Cidade">
              </div>
              <div class="form-group">
                <button type="button" class="btn btn-primary py-3 px-5" @click="enviarDadosFormulario"
                  style="border-radius:25px;">Enviar Mensagem</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

     <section v-if="ListaFaq!=''" class="ftco-section ftco-no-pb ftco-no-pt mb-5" id="chapter-section">
      <div class="container">
        <div class="row justify-content-center py-5 mt-5">
          <div class="col-md-12 heading-section text-center ">
            <h2 class="mb-4">Dúvidas, consulte nossa FAQ</h2>
          </div>
        </div>
        <div class="row justify-content-center">
            <vsa-list>
              <vsa-item v-for="faq in ListaFaq" :key="faq.institucionalId">
                <vsa-heading>
                  {{ faq.nome }}
                </vsa-heading>
                <vsa-content>
                  <p v-html="faq.resumo1"></p>
                </vsa-content>
              </vsa-item>
            </vsa-list>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from '../shared/Header.vue'
//import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    Header,
    //Depoimentos,
  },
  data() {
    return {
      parceiroslogos:[],
      Topo:[],
      imgImob:'',
      tituloPeqImob:'',
      tituloGrImob:'',
      textoImob:'',
      linkImob:'',
      txtBtnImob:'',
      Conheca:[],
      imgConheca:'',
      tituloConheca:'',
      textoConheca:'',
      Depende:[],
      tituloDepende:'',
      ComoFunciona:[],
      contatoEmail:'',
      Encontre:[],
      tituloEncontre:'',
      textoEncontre:'',
       // Objeto Contato  
      nome: '',
			email: '',
			telefone: '',
      estado:'',
      cidade:'',
			mensagem: '',    
      Contato: {
        NomeEmpresa: "Aqui Garante ",
        Assunto: "Contato Proprietários",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      },
      ListaFaq:[],
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/Banner%20Topo%20Prop.")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Topo = retornoAPI
        if (this.Topo!=''){
            this.tituloPeqImob = this.Topo[0].nome
            this.tituloGrImob = this.Topo[0].campoExtra1
            this.textoImob = this.Topo[0].resumo2
            this.txtBtnImob = this.Topo[0].textoBtn1
            this.linkImob = this.Topo[0].linkBtn1
            if ( this.Topo[0].institucionalImg!=''){
             this.imgImob = this.$apiUrlIndex + this.Topo[0].institucionalImg[0].urlImagem  
            }
        }
    });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/Vantagens%20Prop.")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Conheca = retornoAPI
        if (this.Conheca!=''){
            this.tituloConheca = this.Conheca[0].nome
            this.textoConheca = this.Conheca[0].descricao1
            if ( this.Conheca[0].institucionalImg!=''){
                this.imgConheca = this.$apiUrlIndex + this.Conheca[0].institucionalImg[0].urlImagem
            }
              
        }
    });

     this.$http
      .get(this.$apiUrl + "/institucional/telas/Chamada%20Prop.")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Depende = retornoAPI
        if (this.Depende!=''){
            this.tituloDepende = this.Depende[0].nome
        }
    });

    this.$http
        .get(this.$apiUrl + "/institucional/telas/Itens%20Prop.")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.ComoFunciona = retornoAPI.filter((x) => x.visivel)

          this.ComoFunciona.sort(function (a, b) {
            if (a.ordem > b.ordem) { return 1; }       
            if (a.ordem < b.ordem) { return -1; }        
            return 0;
          })
       });    
       
      this.$http
      .get(this.$apiUrl + "/institucional/telas/Formulario%20Prop.")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Encontre = retornoAPI
        if (this.Encontre!=''){
            this.tituloEncontre = this.Encontre[0].nome
            this.textoEncontre = this.Encontre[0].resumo1
             this.contatoEmail = this.Encontre[0].referencia
        }
    });

      this.$http
        .get(this.$apiUrl + "/institucional/telas/faqprop")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.ListaFaq = retornoAPI.filter((x) => x.visivel)

          this.ListaFaq.sort(function (a, b) {
            if (a.ordem > b.ordem) { return 1; }       
            if (a.ordem < b.ordem) { return -1; }        
            return 0;
          })
       });

  },
  methods: {
        enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.contatoEmail
      // this.Contato.Destinatario = "thiagokdma@gmail.com"

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Nome: " + this.nome + 
        " <br /> Telefone: " + this.telefone +
        " <br /> E-mail: " + this.email +
        " <br /> Estado: " + this.estado +
        " <br /> Cidade: " + this.cidade 

      //validar vazios
      if(this.nome == '' || this.telefone == '' || this.email == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //SE NAO TIVER ANEXO:
         console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            this.$mensagem_normal("Enviado com sucesso!")
            this.nome = ''
            this.telefone = ''
            this.email = ''
            this.mensagem = ''
            this.cidade = ''
            this.estado = ''
            this.Contato.Assunto=""
        },error => {
          this.$mensagem_normal(error.bodyText)
          this.nome = ''
          this.telefone = ''
          this.email = ''
          this.mensagem = ''
          this.cidade = ''
            this.estado = ''
          this.Contato.Assunto=""
        });

      }
     
    }
  }
  
}

</script>

<style>
@media (max-width:768px) {
   .alturaimob {
      padding-top: 60px !important;
  }

}

</style>