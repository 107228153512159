<template>

  <div>
    <Header />

    <section class="ftco-section contact-section ftco-no-pb" id="contact-section" style="margin-bottom: 30px;">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-3">
          <div class="col-md-7 heading-section text-center">
            <h2 class="mb-4">{{ topoTitulo }}</h2>
            <p v-html="textoTitulo"></p>
          </div>
        </div>

        <div class="row">
          <!-- Sidenav -->
          <div class="col-lg-4 col-xl-3">
            <h2 class="title-sidebar-suporte mb-4">Informações</h2>
              <h5 v-for="lgpd in InfLgpd" :key="lgpd" style="line-height: 1.2;">
                <a href="" @click.prevent="carregaTexto(lgpd)" class="texto" style="color:#333333;">
                  {{lgpd.nome}}
                </a>
              </h5>
          </div>
          <!-- Main -->
          <div class="col-lg-10 col-xl-9 mt-5 mt-lg-0">
            <h3 class="titulo-principal-suporte mb-4">{{ Titulo }}</h3>
            <!-- <h6 class="subtitulo h5 mb-4">Meus Pedidos</h6> -->
            <div class="row">
              <div class="col">
                <div class="content">
                  <div v-html="Texto"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from '../shared/Header.vue'
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    Header
  },
  data() {
    return {
     ValueId: this.$route.params.id,
     InfLgpd: [],
     Titulo:'',
     Texto:'',
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/info%20lgpd")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.InfLgpd = retornoAPI.filter((x) => x.visivel)

        this.InfLgpd.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })

        if (this.ValueId!=''){
          console.log('this.InfLgpd')
          console.log(this.InfLgpd)
          let info = this.InfLgpd.filter(x => x.institucionalId == parseInt(this.ValueId,10))
                  console.log('info')
                  console.log(info)
          this.carregaTexto(info[0]);
        }

      });
  },
  methods: {
    carregaTexto(value){
     this.Titulo = value.nome
     this.Texto = value.descricao1
    }
  }

}

</script>

