<template>

  <footer class="ftco-footer ftco-section">
    <div class="container">
      <div class="row mb-5">
        <div class="col-md">
          <div class="ftco-footer-widget mb-4">
            <h2 class="ftco-heading-2">Sobre nós</h2>
            <p v-html="sobreRodape"></p>
            <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
              <li class=""><a v-if="facebookLink != ''" :href="facebookLink" target="_blank"><span
                    class="fa fa-facebook"></span></a></li>
              <li class=""><a v-if="instagramLink != ''" :href="instagramLink" target="_blank"><span
                    class="fa fa-instagram"></span></a></li>
            </ul>
          </div>
        </div>
        <div class="col-md">
          <div class="ftco-footer-widget mb-4 ml-md-4">
            <h2 class="ftco-heading-2">Links</h2>
            <ul class="list-unstyled">
              <li>
                <router-link to="/">Home</router-link>
              </li>
              <li v-if="loginLink != '' && loginLink != null && loginLink[0].campoExtra1!='' && loginLink[0].campoExtra1!= null"><a  :href="loginLink[0].campoExtra1" target="_blank"
                  style="text-decoration: none;color: rgba(255, 255, 255, 0.6) !important;">Antecipação</a></li> <!--https://antecipa.garanteaki.com.br/-->
              <li>
                <router-link to="/contato">Contato</router-link>
              </li>
              <li><a v-if="loginLink != '' && loginLink != null " :href="loginLink[0].linkBtn1" target="_blank"
                  style="text-decoration: none;color: rgba(255, 255, 255, 0.6) !important;">Login</a></li>
              <!--li><a v-if="loginLink != '' && loginLink != null" :href="loginLink[0].linkBtn2" target="_blank"
                  style="text-decoration: none;color: rgba(255, 255, 255, 0.6) !important;">Seguros</a></!--li-->
              <li><router-link to="/seguroresidencial">Seguro Residencial</router-link></li>
              <li><router-link to="/segurovida">Seguro de Vida</router-link> </li>
              <li><router-link to="/seguroempresarial">Seguro Empresarial</router-link></li>
              <li><a v-if="loginLink != '' && loginLink != null" :href="loginLink[0].campoExtra2" target="_blank"
                  style="text-decoration: none;color: rgba(255, 255, 255, 0.6) !important;">Garantias Locatícias</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md">
          <div class="ftco-footer-widget mb-4">
            <h2 class="ftco-heading-2">Serviços</h2>
            <ul class="list-unstyled">
              <li>
                <router-link to="/imobiliarias">Imobiliárias</router-link>
              </li>
              <li>
                <router-link to="/locatarios">Locatários</router-link>
              </li>
              <li>
                <router-link to="/proprietarios">Proprietários</router-link>
              </li>
            </ul>
            <h2 class="ftco-heading-2 mb-2">Informações LGPD</h2>
            <ul class="list-unstyled">
              <li v-for="lgpd in InfLgpd" :key="lgpd.institucionalId">
                <router-link :to="'/informacoes/' + lgpd.institucionalId">{{ lgpd.nome }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md">
          <div class="ftco-footer-widget mb-4">
            <h2 class="ftco-heading-2">Vamos conversar?</h2>
            <div class="block-23 mb-3">
              <ul>
                <li v-if="Endereco != '' && Endereco != null"><span class="icon fa fa-map-marker"></span><span class="text"
                    v-html="Endereco"></span></li>
                <li v-if="Fone != '' && Fone != null"><a :href="Link"><span class="icon fa fa-phone"></span><span
                      class="text">{{ Fone }}</span></a></li>
                <li v-if="Email != '' && Email != null">
                  <router-link to="/contato"><span class="icon fa fa-paper-plane"></span><span class="text">{{ Email
                  }}</span></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col text-center py-4 mt-5">
          <a href="https://avancedigital.com.br/" target="_blank"><img src="images/avance.png" alt=""></a>
        </div>
      </div>
    </div>
  </footer>

</template>


<script>
export default {
  data() {
    return {
      infoRodape: [],
      sobreRodape: '',
      Endereco: '',
      Fone: '',
      Email: '',
      Link: '',
      socialFacebook: [],
      facebookLink: '',
      socialInstagram: [],
      instagramLink: '',
      loginLink: [],
      InfLgpd: [],
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/info%20rodape")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.infoRodape = retornoAPI
        if (this.infoRodape != '') {
          this.sobreRodape = this.infoRodape[0].resumo1
          this.Endereco = this.infoRodape[0].resumo2
          this.Fone = this.infoRodape[0].nome
          this.Email = this.infoRodape[0].campoExtra1
          this.Link = this.infoRodape[0].referencia
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/info%20lgpd")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.InfLgpd = retornoAPI.filter((x) => x.visivel)

        this.InfLgpd.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })

      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/facebook")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.socialFacebook = retornoAPI.filter((x) => x.visivel)
        if (this.socialFacebook != '') {
          this.facebookLink = this.socialFacebook[0].linkBtn1
        }


      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/instagram")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.socialInstagram = retornoAPI.filter((x) => x.visivel)
        if (this.socialInstagram != '') {
          this.instagramLink = this.socialInstagram[0].linkBtn1
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/loginlink")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.loginLink = retornoAPI

        this.loginLink.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })

      });

  },
  methods: {
  }
}
</script>

<style>
a {
  text-decoration: none !important;
}
</style>