<template>

  <div>
    <Header />

    <section class="ftco-section contact-section ftco-no-pb" id="contact-section" style="margin-bottom: 30px;">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-3">
          <div class="col-md-7 heading-section text-center">
            <h2 class="mb-4">{{ topoTitulo }}</h2>
            <p v-html="textoTitulo"></p>
          </div>
        </div>

        <div class="row no-gutters block-9">
          <div class="col-md-3 d-flex">

          </div>
          <div class="col-md-6 order-md-last d-flex">
            <form action="#" class="bg-light p-4 p-md-5 contact-form">
              <div class="form-group">
                <input type="text" v-model="nome" class="form-control" placeholder="Nome">
              </div>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Telefone">
              </div>
              <div class="form-group">
                <input type="text" v-model="email" class="form-control" placeholder="E-mail">
              </div>
              <div class="form-group">
                <textarea name="" id="" cols="30" rows="7" class="form-control" placeholder="Mensagem"></textarea>
              </div>
              <div class="form-group">
                <button type="button" class="btn btn-primary py-3 px-5" @click="enviarDadosFormulario" style="border-radius:25px;">Enviar</button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from '../shared/Header.vue'
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    Header
  },
  data() {
    return {
      contato: [],
      topoTitulo:'',
      textoTitulo:'',
      // Objeto Contato  
      nome: '',
			email: '',
			telefone: '',
			mensagem: '',    
      Contato: {
        NomeEmpresa: "Aqui Garante ",
        Assunto: "Contato de Parceiro",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/Formulario%20Parceiros")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.contato = retornoAPI
      if (this.contato !=''){
          this.topoTitulo = this.contato[0].nome
          this.textoTitulo = this.contato[0].descricao1
          this.contatoEmail = this.contato[0].referencia
      }
    });

  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.contatoEmail
      // this.Contato.Destinatario = "thiagokdma@gmail.com"

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Nome: " + this.nome + 
        " <br /> Telefone: " + this.telefone +
        " <br /> Email: " + this.email +
        " <br /> Descrição: " + this.mensagem

      //validar vazios
      if(this.nome == '' || this.telefone == '' || this.email == '' || this.mensagem == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //SE NAO TIVER ANEXO:
        // console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            alert("Enviado com sucesso!")
            this.nome = ''
            this.telefone = ''
            this.email = ''
            this.mensagem = ''
            this.Contato.Assunto=""
        },error => {
          alert(error.bodyText)
          this.nome = ''
          this.telefone = ''
          this.email = ''
          this.mensagem = ''
          this.Contato.Assunto=""
        });

      }
     
    }
  }
  
}

</script>

