<template>

  <div>
    <Header />

    <section v-if="slider!= '' && slider!= null" class="hero-wrap js-fullheight alturaimg alturatopo">

      <div class="container-fluid px-0">
        <div class="row d-md-flex no-gutters slider-text align-items-center js-fullheight justify-content-end">
          <img class="one-third align-content-center order-md-last img-fluid alturaimgcel" :src="$apiUrlIndex + slider[0].institucionalImg[0].urlImagem" alt="">
          <div class="one-forth d-flex align-items-center  ">
            <div class="text mt-5" style="padding-top: 30px;">
              <h1>{{ slider[0].nome}}</h1>
              <p v-html="slider[0].resumo2"></p>
              <a :href="slider[0].linkBtn1" class="btn btn-primary py-3 px-4" style="border-radius: 25px;" target="_blank">{{ slider[0].textoBtn1}}</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-about img" id="about-section" style="padding-top: 10px;padding-bottom: 50px;">
      <div class="container">
        <div class="col-md-12 heading-section text-center ">
          <h2 v-if="chamadaHome!= ''" class="mb-4 alturaimgcel" v-html="chamadaHome[0].descricao1"></h2>
        </div>
      </div>
    </section>

    <section class="ftco-no-pt">
      <div class="container">
        <div class="row">
          <div class="col-md-4 text-center d-flex" v-for="item in itenshome" :key="item.institucionalId">
            <div class="services-1 bg-light">
              <span class="icon">
                <router-link :to="item.referencia">
                   <img :src="$apiUrlIndex + item.institucionalImg[0].urlImagem" style="border-radius: 5px;" />
                </router-link>
              </span>
              <div class="desc">
                <router-link :to="item.referencia">
                   <h2 class="mb-2">{{ item.nome}}</h2>
                </router-link>
                <p v-html="item.resumo1"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="comofunciona!='' && comofunciona!=null" class="ftco-about img ftco-section ftco-no-pt ftco-no-pb" id="author-section">
      <div class="container">
        <div class="row d-flex no-gutters">
          <div class="col-md-12 heading-section text-center " style="padding-bottom: 30px;">
            <h2 class="mb-4">{{ comofunciona[0].nome }}</h2>
          </div>
          <div class="col-md-6 col-lg-6 d-flex">
            <div class="img-about img d-flex align-items-stretch">
              <div v-if="comofunciona[0].institucionalImg!=''" class="img d-flex align-self-stretch align-items-center"
                :style="{ backgroundImage: 'url(' + $apiUrlIndex + comofunciona[0].institucionalImg[0].urlImagem + ')',backgroundSize: 'contain' }" >
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 d-flex">
            <div class="py-md-5 w-100 bg-light px-md-5">
              <div class="py-md-5 px-4">
                <div class="row justify-content-start pb-3">
                  <div class="col-md-12 heading-section ">
                    <h2 class="mb-4" style="padding: 5px;">{{ comofunciona[0].campoExtra1}}</h2>
                    <p v-html="comofunciona[0].descricao1"></p>
                  </div>
                </div>
                <div class="counter-wrap  d-flex mt-md-3">
                  <div class="text">
                    <p class="mb-4 btn-custom">
                      <a :href="comofunciona[0].linkBtn1" target="_blank" style="z-index: 99999;"><span>{{ comofunciona[0].textoBtn1}}</span></a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="numeros!= '' && numeros!=null" class="ftco-section ftco-counter img" id="section-counter" style="padding-bottom: 30px;padding-top: 30px;">
      <div class="container">
        <div class="row d-md-flex align-items-center align-items-stretch">
          <div v-for="num in numeros" :key="num.institucionalId" class="col-md d-flex justify-content-center counter-wrap ">
            <div class="block-18 bg-light">
              <div class="text">
               <strong><VueJsCounter start="0" :end="num.referencia" duration="8000" style="font-weight: 700;font-size: 34px;display: block;color: #263b5e;"></VueJsCounter></strong>
                <span>{{ num.nome }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

     <Depoimentos />

    <section v-if="parceiroslogos!='' && parceiroslogos!=null" class="ftco-section ftco-partner">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg col-6 " v-for="parceiros in parceiroslogos" :key="parceiros.institucionalId">
            <a :href="parceiros.linkBtn1" class="partner d-flex justify-content-center"><img :src="$apiUrlIndex + parceiros.institucionalImg[0].urlImagem" class="img-fluid"
                :alt="parceiros.nome"></a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from '../shared/Header.vue'
import Depoimentos from '../shared/Depoimentos.vue'
//import { Carousel, Slide } from 'vue-carousel';
import VueJsCounter from 'vue-js-counter'


export default {
  components: {
    Header,
    //FiltraImoveis,
     Depoimentos,
      VueJsCounter 
    //Carousel,
    //Slide
   },
  data() {
    return {
      slider: [],
      chamadaHome: [],
      itenshome:[],
      comofunciona:[],
      numeros:[],
      parceiroslogos:[],
    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/Banner%20Topo%20Home")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.slider = retornoAPI

    });

      this.$http
        .get(this.$apiUrl + "/institucional/telas/chamada")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.chamadaHome = retornoAPI

        });

  this.$http
    .get(this.$apiUrl + "/institucional/telas/itenshome")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.itenshome = retornoAPI.filter((x) => x.visivel)

      this.itenshome.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });

     this.$http
    .get(this.$apiUrl + "/institucional/telas/numeros")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.numeros = retornoAPI.filter((x) => x.visivel)

      this.numeros.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/comofunciona")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.comofunciona = retornoAPI
    });

      this.$http
        .get(this.$apiUrl + "/institucional/telas/parceiroslogos")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.parceiroslogos = retornoAPI.filter((x) => x.visivel)

          this.parceiroslogos.sort(function() {
          return .5 - Math.random();
        });
        });
  },
  mounted(){
    let carregascript = document.createElement('script')
    carregascript.setAttribute('src','https://consent.cookiebot.com/uc.js')
    carregascript.setAttribute('id','Cookiebot')
    carregascript.setAttribute('data-cbid','d1060c29-259d-42f8-95a2-ea3d5fbd75be')
    carregascript.setAttribute('data-blockingmode','auto')
    document.head.appendChild(carregascript);
    
    let carregascript2 = document.createElement('script')
    carregascript2.setAttribute('src','https://consent.cookiebot.com/d1060c29-259d-42f8-95a2-ea3d5fbd75be/cd.js')
    carregascript2.setAttribute('id','CookieDeclaration')
    document.head.appendChild(carregascript2);
  },
  methods: {
  }
  
}

</script>


