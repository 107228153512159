<template>

    <b-navbar toggleable="lg" class="container mt-2 navbar navbar-expand-lg navbar-dark ftco_navbar ftco-navbar-light site-navbar-target" >
        <b-navbar-brand>
          <router-link to="/" class="navbar-brand">
            <img  src="/images/logo.jpeg" alt="Logo" class="img-thumbnail" style="border: 0px;width: 190px;padding-top: ;"  />
          </router-link>
            <b-navbar-toggle target="nav-collapse"><i class="fa fa-bars" ></i></b-navbar-toggle>
        </b-navbar-brand>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="navbar-nav nav ml-auto">
              <b-nav-item exact class="nav-link" to="/"><span>Home</span></b-nav-item>
              <b-nav-item class="nav-link" to="/imobiliarias"><span>Imobiliárias</span></b-nav-item>
              <b-nav-item class="nav-link" to="/locatarios"><span>Locatários</span></b-nav-item>
              <b-nav-item class="nav-link" to="/proprietarios"><span>Proprietários</span></b-nav-item>
              <b-nav-item v-if="loginLink!= '' && loginLink[0].campoExtra1!='' && loginLink[0].campoExtra1!= null" class="nav-link" :href="loginLink[0].campoExtra1" target="_blank"><span>Antecipação</span></b-nav-item>
              <!--b-nav-item v-if="loginLink!= '' && loginLink[0].linkBtn2!='' && loginLink[0].linkBtn2!= null" class="nav-link" :href="loginLink[0].linkBtn2" target="_blank"><span>Seguros</span></!--b-nav-item-->
              <b-nav-item-dropdown id="my-nav-dropdown" text="Seguros" class="nav-link" toggle-class="nav-link" right>
                <b-dropdown-item to="/seguroresidencial">Seguro Residencial</b-dropdown-item>
                <b-dropdown-item to="/segurovida">Seguro de Vida</b-dropdown-item>
                <b-dropdown-item to="/seguroempresarial">Seguro Empresarial</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item class="nav-link" to="/contato"><span>Contato</span></b-nav-item>
              <b-nav-item v-if="loginLink!= '' && loginLink[0].linkBtn1!='' && loginLink[0].linkBtn1!= null" class="nav-link" :href="loginLink[0].linkBtn1" target="_blank"><span>Login</span></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar> 
</template>

<script>
// import FiltraImoveis from './FiltraImoveis.vue'
export default {
  //props: ['topoFundo','topoCor'],
  components: {
    // FiltraImoveis
  },
  data() {
    return {
      socialFacebook: [],
      facebookLink: '',

      socialInstagram: [],
      instagramLink: '',

      socialYoutube: [],
      youtubeLink: '',

      socialTwitter: [],
      twitterLink: '',

      loginLink:[],
    }

  },
  created() {
    
     this.$http
        .get(this.$apiUrl + "/institucional/telas/loginlink")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.loginLink = retornoAPI

        }); 

 }

}
</script>

<style>
/* Posiciona a seta do submenu no lado direito */
.dropdown-toggle::after {
  margin-left: 0.5em !important;
  margin-top: 12px !important;
  vertical-align: middle !important;
  border-top: 0.3em solid !important;
  border-right: 0.3em solid transparent !important;
  border-left: 0.3em solid transparent !important;
  content: "" !important;
}

#my-nav-dropdown__BV_toggle_ {
  display: inline-flex;
}

</style>