<template>

<div>
  <Header />


  <section class="ftco-section contact-section ftco-no-pb" id="contact-section" style="margin-bottom: 30px;">
    <div class="container">
      <div class="row justify-content-center mb-5 pb-3">
        <div class="col-md-7 heading-section text-center">
          <h2 class="mb-4">Contratação finalizada com sucesso!</h2>
          <p>Em breve você receberá e-mail com resumo e informações da contração.</p>
          <br>
          <br>
          <strong>Att,</strong><br>
          <strong>Equipe Aqui Garante</strong>
        </div>
      </div>

    
    </div>
  </section>
  </div>
</template>

<script>
import Header from '../shared/Header.vue'
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    Header
  },
  data() {
    return {
      contato: [],
      topoTitulo:'',
      textoTitulo:'',
      contatoEndereco: '',
      contatoTelefones: '',
      contatoEmails: '',
      contatoMapa:'',
      contatoImg:'',
      contatoEmail:'',
      // Objeto Contato  
      nome: '',
			email: '',
			telefone: '',
			mensagem: '',    
      Contato: {
        NomeEmpresa: "Aqui Garante ",
        Assunto: "Contato",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {

   
  },
  methods: {

  }
  
}

</script>

