import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/site/views/Home.vue'
import Imobiliarias from '@/components/site/views/Imobiliarias.vue'
import Locatarios from '@/components/site/views/Locatarios.vue'
import Proprietarios from '@/components/site/views/Proprietarios.vue' 
import Parceiros from '@/components/site/views/Parceiros.vue'
import Contato from '@/components/site/views/Contato.vue'
import Informacoes from '@/components/site/views/Informacoes.vue'
import SeguroResidencial from '@/components/site/views/SeguroResidencial.vue'
import SeguroVida from '@/components/site/views/SeguroVida.vue'
import SeguroEmpresarial from '@/components/site/views/SeguroEmpresarial.vue'
import successpgto from '@/components/site/views/successpgto.vue'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if(savedPosition) {
          return resolve(savedPosition)
        }
        if(to.hash) {
          return resolve({
            selector: to.hash,
            offset: {x: 0, y: 0}
          })
        }
        return resolve({ 
          x: 0, y: 0,
          behavior: 'smooth'
        })
      }, 700) 
    })
  },
  routes: [
    {path: '/', component: Home},
    {path: '/parceiros', component: Parceiros},
    {path: '/imobiliarias', component: Imobiliarias},
   {path: '/locatarios', component: Locatarios},
   {path: '/proprietarios', component: Proprietarios},
    {path: '/contato', component: Contato}, 
    {path: '/informacoes/:id', component: Informacoes}, 
    {path: '/seguroResidencial', component: SeguroResidencial},
    {path: '/seguroVida', component: SeguroVida},
    {path: '/seguroEmpresarial', component: SeguroEmpresarial},
    {path: '/successpgto', component: successpgto}
  ]
})